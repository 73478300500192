<template>
    <vue-json-editor v-model="jsonStructure" :show-btns="false" :expandedOnStart="true" mode="code" @has-error="$emit('on-error', $event)" @json-change="$emit('on-change', $event)"></vue-json-editor>
</template>

<script>
    import vueJsonEditor from 'vue-json-editor';
    export default {
        props: {
            defaultJson: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
        components: {
            vueJsonEditor,
        },
        data() {
            return {
                jsonStructure: {},
            };
        },
        mounted() {
            this.jsonStructure = this.defaultJson;
        },
    };
</script>

<style></style>
