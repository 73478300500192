<template>
    <b-tabs>
        <b-tab title="Structures">
            <structure-tab :brands="brands" :structures="structures" @on-refresh="fetchStructures"></structure-tab>
        </b-tab>
        <b-tab title="Images">
            <image-tab :brands="brands" :structures="structures" :templates="templates" @on-refresh="fetchBannerTemplates"></image-tab>
        </b-tab>
    </b-tabs>
</template>

<script>
    import ImageTab from './Images/index.vue';
    import StructureTab from './Structure/index.vue';
    import { mapGetters, mapActions } from 'vuex';
    export default {
        components: {
            ImageTab,
            StructureTab,
        },
        data() {
            return {
                brands: [],
                structures: [],
                templates: [],
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
        },
        mounted() {
            this.init();
        },
        methods: {
            ...mapActions({
                getBannerBrands: 'app/getBannerBrands',
                getBannerStructures: 'app/getBannerStructures',
                getBannerTemplates: 'app/getBannerTemplates',
            }),
            init() {
                this.$root.toggleGlobalLoading(true);
                let promises = [this.fetchBannerBrands(), this.fetchStructures(), this.fetchBannerTemplates()];
                Promise.all(promises)
                    .then((res) => {})
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la récupération des données'));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
            fetchBannerBrands() {
                return this.getBannerBrands().then((res) => {
                    this.$set(this, 'brands', res.data);
                });
            },
            fetchStructures() {
                return this.getBannerStructures().then((res) => {
                    this.$set(this, 'structures', res.data);
                });
            },
            fetchBannerTemplates() {
                return this.getBannerTemplates().then((res) => {
                    this.$set(this, 'templates', res.data);
                });
            },
        },
    };
</script>

<style></style>
