<template>
    <b-card>
        <template-form :brands="brands" :structures="structures" :templates="templates" @on-refresh="$emit('on-refresh')"></template-form>
        <template-table :templates="templates" :structures="structures" :brands="brands" @on-refresh="$emit('on-refresh')"></template-table>
    </b-card>
</template>

<script>
    import TemplateForm from './template-form.vue';
    import TemplateTable from './table.vue';
    export default {
        components: {
            TemplateForm,
            TemplateTable,
        },
        props: {
            templates: {
                type: Array,
                required: true,
            },
            brands: {
                type: Array,
                required: true,
            },
            structures: {
                type: Array,
                required: true,
            },
        },
    };
</script>

<style></style>
