<template>
    <div>
        <b-btn variant="warning" class="p-1 mr-1" @click="onEdit">
            <feather-icon icon="Edit3Icon"></feather-icon>
        </b-btn>
        <b-btn variant="danger" class="p-1 mr-1" @click="onDelete">
            <feather-icon icon="TrashIcon"></feather-icon>
        </b-btn>
        <template-edit-modal ref="modal-edit" :templateData="templateData" :structures="structures" :brands="brands"></template-edit-modal>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import TemplateEditModal from './Modals/edit.vue';
    export default {
        components: {
            TemplateEditModal,
        },
        props: {
            templateData: {
                type: Object,
                required: true,
            },
            structures: {
                type: Array,
                required: true,
            },
            brands: {
                type: Array,
                required: true,
            },
        },
        computed: {
            ...mapGetters({
                modalContext: 'app/modalContext',
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
        },
        methods: {
            onEdit() {
                this.$refs['modal-edit'].open();
            },
            onDelete() {
                this.$bvModal
                    .msgBoxConfirm('Êtes-vous sûr de vouloir supprimer le template ?', this.modalContext('danger'))
                    .then((confirmed) => {
                        if (confirmed) this.deleteTemplate();
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
            deleteTemplate() {
                this.$root.toggleGlobalLoading(true);
                this.$store
                    .dispatch('app/deleteTemplate', this.templateData.code)
                    .then((res) => {
                        this.$toast(this.successToast('Le template a été supprimée avec succès'));
                        this.$emit('on-refresh');
                    })
                    .catch((e) => {
                        this.$toast(this.successToast('Erreur lors de la suppresion du template'));
                        console.error(e);
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
        },
    };
</script>

<style></style>
