<template>
    <div class="mt-2">
        <b-input-group class="mb-1 w-25 d-flex ml-auto">
            <b-input-group-prepend>
                <b-input-group-text>
                    <feather-icon icon="SearchIcon"></feather-icon>
                </b-input-group-text>
            </b-input-group-prepend>
            <b-input v-model="filter"></b-input>
        </b-input-group>
        <b-table ref="table" striped responsive :filter="filter" :items="templatesForTable" :fields="fields" :per-page="perPage" :current-page="currentPage" @filtered="onTableFiltered">
            <template #cell(Actions)="data">
                <template-action :key="currentPage + filter + data.item.code" :template-data="data.item" :brands="brands" :structures="structures" @on-refresh="$emit('on-refresh')"></template-action>
            </template>
        </b-table>
        <b-pagination class="mt-3" v-model="currentPage" align="right" :total-rows="rows" :per-page="perPage" first-number></b-pagination>
    </div>
</template>

<script>
    import TemplateAction from './template-action.vue';
    export default {
        components: {
            TemplateAction,
        },
        props: {
            templates: {
                type: Array,
                required: true,
            },
            brands: {
                type: Array,
                required: true,
            },
            structures: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                fields: [{ key: 'name', label: 'Nom du template' }, { key: 'path', label: 'Path' }, 'Actions'],
                perPage: 10,
                currentPage: 1,
                filter: '',
                rows: 0,
            };
        },
        computed: {
            templatesForTable() {
                if (this.filter === '') this.setRows(this.templates);
                return this.templates;
            },
        },
        methods: {
            setRows(list) {
                this.rows = list.length;
            },
            onTableFiltered(list) {
                this.setRows(list);
            },
        },
    };
</script>

<style></style>
