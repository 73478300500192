<template>
    <div class="mt-2">
        <b-input-group class="mb-1 w-25 d-flex ml-auto">
            <b-input-group-prepend>
                <b-input-group-text>
                    <feather-icon icon="SearchIcon"></feather-icon>
                </b-input-group-text>
            </b-input-group-prepend>
            <b-input v-model="filter" debounce="500"></b-input>
        </b-input-group>
        <b-table ref="table" striped responsive :filter="filter" :items="structuresForTable" :fields="fields" :per-page="perPage" :current-page="currentPage" @filtered="onTableFiltered">
            <template #cell(name)="data">
                <div style="width: 100px">
                    {{ data.value }}
                </div>
            </template>
            <template #cell(details)="data">
                <json-structure :key="currentPage + filter + data.item.id" style="width: 500px" :default-json="data.value"></json-structure>
            </template>
            <template #cell(Actions)="data">
                <structure-action :key="currentPage + filter + data.item.code" :structure-data="data.item" :structures="structures" @on-refresh="$emit('on-refresh')"></structure-action>
            </template>
        </b-table>
        <b-pagination class="mt-3" v-model="currentPage" align="right" :total-rows="rows" :per-page="perPage" first-number></b-pagination>
    </div>
</template>

<script>
    import StructureAction from './structure-action.vue';
    import JsonStructure from './json-structure.vue';
    export default {
        components: {
            StructureAction,
            JsonStructure,
        },
        props: {
            structures: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                fields: [{ key: 'name', label: 'Nom de la structure' }, { key: 'details', label: 'Structure' }, 'Actions'],
                perPage: 10,
                currentPage: 1,
                filter: '',
                rows: 0,
            };
        },
        computed: {
            structuresForTable() {
                if (this.filter === '') this.setRows(this.structures);
                return this.structures;
            },
        },
        methods: {
            setRows(list) {
                this.rows = list.length;
            },
            onTableFiltered(list) {
                this.setRows(list);
            },
        },
    };
</script>

<style></style>
