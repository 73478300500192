<template>
    <b-overlay :show="loading">
        <h3>Ajout d'un template</h3>
        <b-form @submit.stop.prevent>
            <b-row>
                <b-col cols="6">
                    <b-form-group label="Nom du template" label-for="input-template-name">
                        <b-input id="input-template-name" v-model="templateName" :state="ruleTemplateName || !showFormError ? null : false"></b-input>
                        <b-form-invalid-feedback :state="ruleTemplateName || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>

                <b-col cols="6">
                    <b-form-group label="Marque" label-for="input-brand">
                        <b-form-select
                            id="input-brand"
                            v-model="selectedBrand"
                            :options="brands"
                            text-field="label"
                            value-field="code"
                            :state="ruleSelectedBrand || !showFormError ? null : false"
                        ></b-form-select>
                        <b-form-invalid-feedback :state="ruleSelectedBrand || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>

                <b-col cols="6" class="d-flex align-items-center flex-wrap" :style="{ marginTop: ruleFile ? (showFormError && !formValidation ? '-11px' : '5px') : '15px' }">
                    <b-btn variant="primary" style="padding: 10px" @click="openFileSelection">
                        <feather-icon icon="UploadIcon" />
                        Importer .zip
                    </b-btn>
                    <b-alert show class="ml-1 flex-grow-1 m-0 text-center" style="padding: 10px">{{ alertText }}</b-alert>
                    <b-form-file ref="file-input" v-model="file" class="d-none" accept=".zip"></b-form-file>
                    <b-form-invalid-feedback :state="ruleFile || !showFormError ? null : false"> Choisissez un fichier à importer </b-form-invalid-feedback>
                </b-col>

                <b-col cols="6">
                    <b-form-group label="Structure" label-for="input-structure">
                        <b-form-select
                            id="input-structure"
                            v-model="selectedStructure"
                            :options="structures"
                            text-field="name"
                            value-field="code"
                            :state="ruleSelectedStructure || !showFormError ? null : false"
                        ></b-form-select>
                        <b-form-invalid-feedback :state="ruleSelectedStructure || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-btn variant="success" class="d-flex ml-auto" @click="confirmTemplateUpload">Créer template</b-btn>
        </b-form>
    </b-overlay>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        props: {
            templates: {
                type: Array,
                required: true,
            },
            brands: {
                type: Array,
                required: true,
            },
            structures: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                templateName: '',
                selectedBrand: '',
                selectedStructure: '',
                file: null,
                showFormError: false,
                loading: false,
            };
        },
        computed: {
            ...mapGetters({
                modalContext: 'app/modalContext',
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            ruleTemplateName() {
                return this.templateName !== '';
            },
            ruleSelectedBrand() {
                return this.selectedBrand !== '';
            },
            ruleSelectedStructure() {
                return this.selectedStructure !== '';
            },
            ruleFile() {
                return this.file !== null;
            },
            formValidation() {
                return [this.ruleTemplateName, this.ruleSelectedBrand, this.ruleFile, this.ruleSelectedStructure].every((val) => val === true);
            },
            alertText() {
                if (this.file) {
                    return this.file.name.length > 15 ? this.file.name.slice(0, 14) + '...zip' : this.file.name;
                }
                return 'Aucun template importé.';
            },
        },
        methods: {
            confirmTemplateUpload() {
                if (!this.formValidation) return (this.showFormError = true);

                this.$bvModal
                    .msgBoxConfirm('Êtes-vous sûr de vouloir ajouter le template ?', this.modalContext())
                    .then((confirmed) => {
                        if (confirmed) this.createTemplate();
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
            openFileSelection() {
                this.$refs['file-input'].$el.childNodes[0].click();
            },
            createTemplate() {
                this.loading = true;
                let data = new FormData();

                data.append('name', this.templateName);
                data.append('brandCode', this.selectedBrand);
                data.append('structureImageCode', this.selectedStructure);
                data.append('file', this.file);

                this.$store
                    .dispatch('app/createTemplate', data)
                    .then((res) => {
                        this.$toast(this.successToast('Template crée avec succès'));
                        this.$emit('on-refresh');
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la création du template'));
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },
    };
</script>

<style></style>
