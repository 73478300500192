<template>
    <b-overlay :show="loading">
        <h3>Ajout d'une structure d'image</h3>
        <b-form @submit.stop.prevent>
            <b-form-group label="Nom de la structure" label-for="input-structure-name">
                <b-input id="input-structure-name" v-model="structureName" :state="ruleStructureName || !showFormError ? null : false"></b-input>
                <b-form-invalid-feedback :state="ruleStructureName || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Structure" label-for="input-structure">
                <json-structure :default-json="jsonStructure" @on-error="errorOnjsonStructure" @on-change="updateJsonStructure"></json-structure>
                <b-form-invalid-feedback :state="ruleJsonStructure || !showFormError ? null : false">
                    Ce champ doit être renseigné et être un json valide. erreur remonté par le json: <i>{{ jsonError }}</i>
                </b-form-invalid-feedback>
            </b-form-group>

            <b-btn variant="success" class="d-flex ml-auto" @click="confirmStructureUpload">Créer structure</b-btn>
        </b-form>
    </b-overlay>
</template>

<script>
    import { mapGetters } from 'vuex';
    import JsonStructure from './json-structure.vue';
    export default {
        components: {
            JsonStructure,
        },
        props: {
            structures: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                structureName: '',
                selectedBrand: '',
                jsonStructure: {},
                jsonError: '',
                ruleJsonStructure: true,
                file: null,
                showFormError: false,
                loading: false,
            };
        },
        computed: {
            ...mapGetters({
                modalContext: 'app/modalContext',
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            ruleStructureName() {
                return this.structureName !== '';
            },
            formValidation() {
                return [this.ruleStructureName, this.ruleJsonStructure].every((val) => val === true);
            },
        },
        mounted() {},
        methods: {
            errorOnjsonStructure(error) {
                this.jsonError = error;
                this.ruleJsonStructure = false;
            },
            updateJsonStructure(val) {
                this.ruleJsonStructure = true;
                this.jsonStructure = val;
            },
            confirmStructureUpload() {
                if (!this.formValidation) return (this.showFormError = true);

                this.$bvModal
                    .msgBoxConfirm('Êtes-vous sûr de vouloir ajouter la structure ?', this.modalContext())
                    .then((confirmed) => {
                        if (confirmed) this.createStructure();
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
            createStructure() {
                this.loading = true;
                let data = {
                    name: this.structureName,
                    details: this.jsonStructure,
                };

                this.$store
                    .dispatch('app/createStructure', data)
                    .then((res) => {
                        this.$toast(this.successToast('Structure crée avec succès'));
                        this.$emit('on-refresh');
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la création de la structure'));
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },
    };
</script>

<style></style>
