<template>
    <generic-confirm-modal ref="modal" title="Modification de bannière" cancel-title="Annuler" ok-title="Modifier" @on-accept="updateTemplate" @on-cancel="closeModal">
        <b-overlay :show="loading">
            <b-form @submit.stop.prevent>
                <b-form-group label="Nom du template" label-for="input-template-name">
                    <b-input id="input-template-name" v-model="templateName" :state="ruleTemplateName || !showFormError ? null : false"></b-input>
                    <b-form-invalid-feedback :state="ruleTemplateName || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label="Marque" label-for="input-brand">
                    <b-form-select
                        id="input-brand"
                        v-model="selectedBrand"
                        :options="brands"
                        text-field="label"
                        value-field="id"
                        :state="ruleSelectedBrand || !showFormError ? null : false"
                    ></b-form-select>
                    <b-form-invalid-feedback :state="ruleSelectedBrand || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                </b-form-group>
            </b-form>
        </b-overlay>
    </generic-confirm-modal>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        props: {
            brands: {
                type: Array,
                required: true,
            },
            templateData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                showFormError: false,
                templateName: '',
                selectedBrand: '',
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            ruleTemplateName() {
                return this.templateName !== '';
            },
            ruleSelectedBrand() {
                return this.selectedBrand !== '';
            },
            formValidation() {
                return [this.ruleTemplateName, this.ruleSelectedBrand].every((val) => val === true);
            },
        },
        mounted() {
            this.selectedBrand = this.templateData.brand.id;
            this.templateName = this.templateData.name;
        },
        methods: {
            updateTemplate() {
                if (!this.formValidation) return (this.showFormError = true);
                var data = {
                    name: this.templateName,
                    brandId: this.selectedBrand,
                };
                let relatedBrand = this.brands.find((br) => br.id === data.brandId);
                if (!relatedBrand) {
                    return console.error('BRAND NOT FOUND');
                }
                data.brand = relatedBrand.brand;
                let code = this.templateData.code;
                this.loading = true;

                this.$store
                    .dispatch('app/updateTemplate', { code, data })
                    .then((res) => {
                        this.$toast(this.successToast('Le template a été modifiée avec succès'));
                        this.closeModal();
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la modification du template'));
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            open() {
                this.$refs.modal.open();
            },
            closeModal() {
                this.$refs.modal.close();
            },
        },
    };
</script>

<style></style>
