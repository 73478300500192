<template>
    <generic-confirm-modal ref="modal" title="Modification de bannière" cancel-title="Annuler" ok-title="Modifier" size="lg" @on-accept="updateStructure" @on-cancel="closeModal">
        <b-overlay :show="loading">
            <b-form @submit.stop.prevent>
                <b-form-group label="Nom de la structure" label-for="input-structure-name">
                    <b-input id="input-structure-name" v-model="structureName" :state="ruleStructureName || !showFormError ? null : false"></b-input>
                    <b-form-invalid-feedback :state="ruleStructureName || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label="Structure">
                    <json-structure :default-json="jsonStructure" @on-error="errorOnjsonStructure" @on-change="updateJsonStructure"></json-structure>
                    <b-form-invalid-feedback :state="rulesJsonStructure || !showFormError ? null : false">
                        Ce champ doit être renseigné et être un json valide.<br />Erreur remonté par le json: <i>{{ jsonError }}</i>
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-form>
        </b-overlay>
    </generic-confirm-modal>
</template>

<script>
    import { mapGetters } from 'vuex';
    import JsonStructure from '../json-structure.vue';
    export default {
        components: {
            JsonStructure,
        },
        props: {
            structureData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                showFormError: false,
                structureName: '',
                jsonStructure: '',
                jsonError: '',
                rulesJsonStructure: true,
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            ruleStructureName() {
                return this.structureName !== '';
            },
            formValidation() {
                return [this.ruleStructureName, this.rulesJsonStructure].every((val) => val === true);
            },
        },
        mounted() {
            this.structureName = this.structureData.name;
            this.jsonStructure = this.structureData.details;
        },
        methods: {
            errorOnjsonStructure(error) {
                this.jsonError = error;
                this.rulesJsonStructure = false;
            },
            updateJsonStructure(val) {
                this.ruleJsonStructure = true;
                this.jsonStructure = val;
            },
            updateStructure() {
                if (!this.formValidation) return (this.showFormError = true);
                var data = {
                    name: this.structureName,
                    details: this.jsonStructure,
                };

                let code = this.structureData.code;
                this.loading = true;

                this.$store
                    .dispatch('app/updateStructure', { code, data })
                    .then((res) => {
                        this.$toast(this.successToast('La structure a été modifiée avec succès'));
                        this.closeModal();
                        this.$emit('on-refresh');
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la modification de la structure'));
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            open() {
                this.$refs.modal.open();
            },
            closeModal() {
                this.$refs.modal.close();
            },
        },
    };
</script>

<style></style>
