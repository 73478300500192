<template>
    <b-card>
        <structure-form :structures="structures" @on-refresh="$emit('on-refresh')"></structure-form>
        <structure-table :structures="structures" @on-refresh="$emit('on-refresh')"></structure-table>
    </b-card>
</template>

<script>
    import StructureForm from './structure-form.vue';
    import StructureTable from './table.vue';
    export default {
        components: {
            StructureForm,
            StructureTable,
        },
        props: {
            brands: {
                type: Array,
                required: true,
            },
            structures: {
                type: Array,
                required: true,
            },
        },
    };
</script>

<style></style>
